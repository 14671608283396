<template>
  <div>
    <div class="d-flex justify-content-end">
      <b-button
        variant="outline-secondary"
        @click="showCreate = true"
      >
        <feather-icon icon="PlusIcon" />  Create Category
      </b-button>
    </div>
    <create-category
      :show="showCreate"
      :categories="categories"
      @close="showCreate = false"
      @refetch="getJobCategory"
    />
    <h4>Category List</h4>
    <b-overlay :show="isProcessing">
      <!-- types -->
      <app-collapse
        accordion
        type="shadow"
        class="mt-3"
      >
        <app-collapse-item
          v-for="category of categories"
          :key="category.displayName"
          :title="category.displayName"
        >
          <b-button
            v-for="sub,index of category.subCategories"
            :key="index"
            v-b-tooltip.hover.top="sub.description"
            class="mr-1"
            variant="outline-primary"
          >
            {{ sub.displayName }}
          </b-button>
        </app-collapse-item>
      </app-collapse>
    </b-overlay>
  </div>
</template>
<script>
import { BButton, BOverlay, VBTooltip } from 'bootstrap-vue'
import { reactive, toRefs } from '@vue/composition-api'
import useApollo from '@/plugins/graphql/useApollo'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import CreateCategory from './components/create-category.vue'

export default {
  components: {
    BButton,
    BOverlay,
    CreateCategory,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const state = reactive({
      showCreate: false,
      categories: [],
      isProcessing: false,
    })
    function getJobCategory() {
      state.isProcessing = true
      useApollo.jobPortal.getJobCategory().then(response => {
        state.categories = response.data.jobCategories.data
      }).finally(() => {
        state.isProcessing = false
      })
    }
    getJobCategory()

    return {
      ...toRefs(state),
      getJobCategory,
    }
  },
}
</script>

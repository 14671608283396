<template>
  <b-card
    no-body
    :class="{'open': visible}"
    class="mt-2"
    @mouseenter="collapseOpen"
    @mouseleave="collapseClose"
  >
    <b-card-header
      :class="{'collapsed': !visible}"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      @click="updateVisible(!visible)"
    >
      <slot name="header">
        <span class="lead collapse-title">{{ title }}</span>

      </slot>
    </b-card-header>

    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body>
        <p
          v-if="showHtml"
          v-html="answer"
        />
        <slot v-else>
          {{ answer }}
        </slot>
      </b-card-body>
    </b-collapse>
    <div
      v-if="canUpdate"
      class="edit-icons"
    >
      <span @click="$emit('edit')">
        <feather-icon
          icon="EditIcon"
          class="text-workzone"
        />
      </span>
      <span
        class="ml-1 "
        @click="$emit('delete')"
      >
        <feather-icon
          icon="TrashIcon"
          class="text-danger"
        />
      </span>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCollapse,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    answer: {
      type: String,
      default: '',
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
    showHtml: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : null
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
  },
}
</script>

<style lang="scss">
.edit-icons{
  position: absolute;
  right:0;
  top:-10px;

  span{
    background-color: #dcdcff;
    border-radius: 50%;
    padding:5px;
    font-weight: 800;
    cursor:pointer;
  }
}
</style>
